<template>
  <el-button v-debounce="getResultData" :disabled="disabled">{{loading?'加载中':'点击获取'}}</el-button>
  <el-input type="textarea" v-model="convertData" disabled="true" :autosize></el-input>
</template>

<script>
export default {
  props:{
    //预定义
    disabled:Boolean,
    modelValue: [String,Number], // 接收表单的值
    formCreateInject: Object,
  },
  data(){
    return{
      convertData:this.modelValue,
      loading:false
    }
  },
  mounted() {
    // console.log(123,this.formCreateInject);
  },
  // render($h){
  //   return $h('rmbCapital',{
  //     props:{
  //       disabled: this.disabled,
  //       value:this.value,
  //       long: true
  //     },
  //     on: {
  //       'on-change': function(){
  //         this.onChange();
  //       }
  //     }
  //   })
  // },
  watch:{
    modelValue(n){
      //同步 value 的值
      this.convertData=n;
    }
  },
  methods:{
    getResultData(){
      let vm=this;
      let props=vm.formCreateInject.rule.props;
      // console.log(props);
      if(!vm.loading && props?.action){
        vm.loading=true;
        let url=props.action;
        if(!props.method || props.method=='GET'){
          vm.$http.get(url).then(res=>{
            // console.log(res);
            if(res.status==200 && res.data.code==200){
              vm.convertData=res.data.data.join('，');
              this.$emit('update:modelValue', vm.convertData);
            }
            vm.loading=false;
          })
        }else if(props.method=='POST'){
          vm.$http.post(url).then(res=>{
            if(res.status==200 && res.data.code==200){
              vm.convertData=res.data.data.join('，');
              this.$emit('update:modelValue', vm.convertData);
            }
            vm.loading=false;
          })
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
