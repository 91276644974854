<template>
  <el-input type="number" v-model="convertData" disabled="true" ></el-input>
</template>

<script>
export default {
  props:{
    //预定义
    disabled:Boolean,
    modelValue: [Number], // 接收表单的值
    formCreateInject: Object,
  },
  data(){
    return{
      convertData:this.modelValue
    }
  },
  mounted() {
    // console.log(123,this.formCreateInject);
  },
  // render($h){
  //   return $h('rmbCapital',{
  //     props:{
  //       disabled: this.disabled,
  //       value:this.value,
  //       long: true
  //     },
  //     on: {
  //       'on-change': function(){
  //         this.onChange();
  //       }
  //     }
  //   })
  // },
  watch:{
    modelValue(n){
      //同步 value 的值
      this.convertData=n;
    }
  },
}
</script>

<style scoped>

</style>
