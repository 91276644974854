// 统一管理用户相关接口
import request from '@/utils/request'
import type { LoginFormData, LoginResponseData, userInfoResponseData } from './type'

enum API {
  COMMON_URL = '/jsonrpc',
  USERINFO_URL = '/user/info'
}

export const requestJsonrpc = (data: LoginFormData) => request.post<any, LoginResponseData>(API.COMMON_URL, data)

export const reqUserInfo = () => request.get<any, userInfoResponseData>(API.USERINFO_URL)