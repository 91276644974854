// 进行axios二次封装：使用请求与响应拦截器

import axios from 'axios'
// 第一步：利用axios对象的create方法，创建axios实例（其他配置：基础路径，超时时间。。。）
let request = axios.create({
  // 基础路由
  // baseURL: 'http://47.121.212.137:8069',
  timeout: 5000
})
// 第二步： request实例添加请求与响应拦截器
request.interceptors.request.use((config: any) => {
  // config配置对象，headers属性请求头
  // 返回配置对象
  return config
})
// 第三步：响应拦截器
request.interceptors.response.use(
  (response: any) => {
    // 成功回调
    if (response.status === 200) {
      return Promise.resolve(response.data)
    } else {
      return Promise.reject(response.data)
    }
  },
  (error: any) => {
    // 错误回调
    // let message = ''
    // let status = error.response.status
    // switch (status) {
    //   case 401:
    //     message = '未登录'
    //     break;
    //   default:
    //     message = error.response.data.message
    //     break
    // }
    return Promise.reject(error)
  }
)

// 对外暴露
export default request