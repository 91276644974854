import {
    createRouter,
    createWebHashHistory,
    createWebHistory
} from "vue-router"
import store from '../store';

// const routerHistory = createWebHashHistory();  // 采用hash 模式
const routerHistory = createWebHistory("/"); // 采用history 模式

const routes = [{
        path: '/login',
        name: "login",
        meta: {
            isHidden: false,
            icon: '',
            title: '登录'
        },
        component: () => import("@/views/login/Login.vue"),
    },
    // 开始-- 主界面
    {
        // path:'',
        path: '/',
        name: "main",
        meta: {
            isHidden: false,
            icon: '',
            title: 'HOME'
        },
        redirect: {
            name: 'home'
        },
        component: () => import("@/views/main/Main.vue"),
        children: [{
                path: '',
                name: "home",
                meta: {
                    isHidden: false,
                    icon: '',
                    title: 'HOME'
                },
                component: () => import("@/views/home/Home.vue"),
                // component: () => import("@/views/home/HomeOld.vue"),
            },
            // 企业架构--新版
            {
                path: '/enterpriseOrganization',
                name: "enterpriseOrganization",
                meta: {
                    isHidden: false,
                    icon: '',
                    title: '企业组织架构'
                },
                component: () => import("@/views/enterpriseOrganization/EnterpriseOrganization.vue"),
                redirect: {
                    name: 'companyManage'
                },
                children: [{
                        path: '/enterpriseOrganization/companyManage',
                        name: "companyManage",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '公司组织架构'
                        },
                        component: () => import("@/views/enterpriseOrganization/companyManage/CompanyManage.vue"),
                    },
                    {
                        path: '/enterpriseOrganization/departmentManage',
                        name: "departmentManage",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '部门组织架构'
                        },
                        component: () => import("@/views/enterpriseOrganization/departmentManage/DepartmentManage.vue"),
                    },
                    {
                        path: '/enterpriseOrganization/positionManage',
                        name: "positionManage",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '职位组织架构'
                        },
                        component: () => import("@/views/enterpriseOrganization/positionManage/PositionManage.vue"),
                    },
                    {
                        path: '/enterpriseOrganization/employeeManage',
                        name: "employeeManage",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '员工信息'
                        },
                        component: () => import("@/views/enterpriseOrganization/employeeManage/EmployeeManage.vue"),
                    },
                    {
                        path: '/enterpriseOrganization/groupEmployeeManage',
                        name: "groupEmployeeManage",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '分组管理'
                        },
                        component: () => import("@/views/enterpriseOrganization/groupEmployeeManage/GroupEmployeeManage.vue"),
                    },
                    {
                        path: '/enterpriseOrganization/businessRole',
                        name: "businessRole",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '业务角色'
                        },
                        component: () => import("@/views/enterpriseOrganization/businessRole/BusinessRole.vue"),
                    }
                ]
            },
            // 企业架构--旧版
            {
                path: '/enterprise',
                name: "enterprise",
                meta: {
                    isHidden: false,
                    icon: '',
                    title: '企业组织架构'
                },
                component: () => import("@/views/enterprise/Enterprise.vue"),
                redirect: {
                    name: 'companyInfo'
                },
                children: [{
                        path: '/enterprise/companyInfo',
                        name: "companyInfo",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '公司信息'
                        },
                        component: () => import("@/views/enterprise/company/CompanyInfo.vue"),
                    },
                    {
                        path: '/enterprise/departmentInfo',
                        name: "departmentInfo",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '部门信息'
                        },
                        component: () => import("@/views/enterprise/department/DepartmentInfo.vue"),
                    },
                    {
                        path: '/enterprise/employeesInfo',
                        name: "employeesInfo",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '员工信息'
                        },
                        component: () => import("@/views/enterprise/employee/EmployeesInfo.vue"),
                    }
                ]
            },
            // 工作流-流程管理
            {
                path: '/workflow/processManage',
                name: "processManage",
                meta: {
                    isHidden: false,
                    icon: '',
                    title: '流程管理'
                },
                component: () => import("@/views/workflow/processManage/ProcessManage.vue"),
                redirect: {
                    name: 'processModel1'
                },
                children: [{
                        path: '/workflow/processManage/processFormConfig',
                        name: "processFormConfig",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '流程表单'
                        },
                        component: () => import("@/views/workflow/processManage/formCreate/FormCreateList.vue"),
                    },
                    {
                        path: '/workflow/processManage/formCreateDesigner',
                        name: "formCreateDesigner",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '设计表单'
                        },
                        component: () => import("@/views/workflow/processManage/formCreate/FormCreateDesigner.vue"),
                    },
                    {
                        path: '/workflow/processManage/processClassify',
                        name: "processClassify",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '流程分类'
                        },
                        component: () => import("@/views/workflow/processManage/processClassify/ProcessClassify.vue"),
                    },
                    {
                        path: '/workflow/processManage/processBusinessType',
                        name: "processBusinessType",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '流程业务类型'
                        },
                        component: () => import("@/views/workflow/processManage/processBusinessType/ProcessBusinessType.vue"),
                    },
                    {
                        path: '/workflow/processManage/processInitiationType',
                        name: "processInitiationType",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '流程启动类型'
                        },
                        component: () => import("@/views/workflow/processManage/processInitiationType/ProcessInitiationType.vue"),
                    },
                    {
                        path: '/workflow/processManage/submitButton',
                        name: "submitButton",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '提交按钮类型'
                        },
                        component: () => import("@/views/workflow/processManage/submitButton/SubmitButton.vue"),
                    },
                    {
                        path: '/workflow/processManage/processModel1',
                        name: "processModel1",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '流程模型'
                        },
                        component: () => import("@/views/workflow/processManage/processModel/ProcessModel1.vue"),
                    },
                    {
                        path: '/workflow/processManage/formFieldMap',
                        name: "formFieldMap",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '表单与数据填报字段匹配'
                        },
                        component: () => import("@/views/workflow/processManage/formFieldMap/FormFieldMap.vue"),
                    },
                    {
                        path: '/workflow/processManage/assigningTasks',
                        name: "assigningTasks",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '流程任务分配规则'
                        },
                        component: () => import("@/views/workflow/processManage/processModel/AssigningTasks.vue"),
                    },
                    {
                        path: '/workflow/processManage/processScheduledFilling',
                        name: "processScheduledFilling",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '设置定时填报'
                        },
                        component: () => import("@/views/workflow/processManage/processScheduledFilling/ProcessScheduledFilling.vue"),
                    },
                    {
                        path: '/workflow/timedTasks',
                        name: "timedTasks",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '设置定时任务'
                        },
                        component: () => import("@/views/workflow/timedTasks/TimedTasks.vue"),
                    },
                    //---------------  旧  ---------------------
                    {
                        path: '/workflow/processManage/processModel',
                        name: "processModel",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '流程模型(旧版)'
                        },
                        component: () => import("@/views/workflow/processManage/processModel/ProcessModel.vue"),
                    },
                    {
                        path: '/workflow/processManage/assigningUsers',
                        name: "assigningUsers",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '任务分配'
                        },
                        component: () => import("@/views/workflow/processManage/processModel/AssigningUsers.vue"),
                    },

                    //  ---- 弃用 -----
                    {
                        path: '/workflow/processManage/processForm',
                        name: "processForm",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '流程表单'
                        },
                        component: () => import("@/views/workflow/processManage/processForm/ProcessForm.vue"),
                    },
                    {
                        path: '/workflow/processManage/processFormEdit',
                        name: "processFormEdit",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '设计表单'
                        },
                        component: () => import("@/views/workflow/processManage/processForm/ProcessFormEdit.vue"),
                    },
                    {
                        path: '/workflow/processManage/ProcessFormView',
                        name: "ProcessFormView",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '表单详情'
                        },
                        component: () => import("@/views/workflow/processManage/processForm/ProcessFormView.vue"),
                    }

                ]
            },
            // 工作流-任务管理
            {
                path: '/workflow/taskHanding',
                name: "taskHanding",
                meta: {
                    isHidden: false,
                    icon: '',
                    title: '任务管理'
                },
                component: () => import("@/views/workflow/taskHanding/TaskHanding.vue"),
                redirect: {
                    name: 'initiateProcess1'
                },
                children: [
                    {
                        path: '/workflow/initiateApplication1',
                        name: "initiateApplication1",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '发起流程'
                        },
                        component: () => import("@/views/workflow/processInstance/InitiateApplication1.vue"),
                    },
                    {
                        path: '/workflow/taskHanding/initiateProcess1',
                        name: "initiateProcess1",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '发起申请'
                        },
                        component: () => import("@/views/workflow/taskHanding/initiateProcess/InitiateProcess1.vue"),
                    },
                    {
                        path: '/workflow/initiateApplication2',
                        name: "initiateApplication2",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '申请页面'
                        },
                        component: () => import("@/views/workflow/taskHanding/initiateProcess/InitiateApplication2.vue"),
                    },
                    {
                        path: '/workflow/taskHanding/initiateProcess',
                        name: "initiateProcess",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '我发起的流程'
                        },
                        component: () => import("@/views/workflow/taskHanding/initiateProcess/InitiateProcess.vue"),
                    },
                    {
                        path: '/workflow/taskHanding/applicationDoing',
                        name: "applicationDoing",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '申请进行中'
                        },
                        component: () => import("@/views/workflow/taskHanding/applicationDoing/ApplicationDoing.vue"),
                    },
                    {
                        path: '/workflow/taskHanding/applicationDone',
                        name: "applicationDone",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '申请已完成'
                        },
                        component: () => import("@/views/workflow/taskHanding/applicationDone/ApplicationDone.vue"),
                    },
                    {
                        path: '/workflow/processDetails1',
                        name: "processDetails1",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '流程详情'
                        },
                        component: () => import("@/views/workflow/processInstance/ProcessDetails1.vue"),
                    },
                    {
                        path: '/workflow/taskHanding/myTimedTasks',
                        name: "myTimedTasks",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '我的任务'
                            // title: '定时任务'
                        },
                        component: () => import("@/views/workflow/taskHanding/myTimedTasks/MyTimedTasks.vue"),
                        redirect: {
                            name: 'timedTasksTodo'
                        },
                        children: [{
                                path: '/workflow/taskHanding/timedTasksTodo',
                                name: "timedTasksTodo",
                                meta: {
                                    isHidden: false,
                                    icon: '',
                                    title: '待办任务'
                                },
                                component: () => import("@/views/workflow/taskHanding/myTimedTasks/TimedTasksTodo.vue")
                            },
                            {
                                path: '/workflow/taskHanding/timedTasksCompleted',
                                name: "timedTasksCompleted",
                                meta: {
                                    isHidden: false,
                                    icon: '',
                                    title: '已完成任务'
                                },
                                component: () => import("@/views/workflow/taskHanding/myTimedTasks/TimedTasksCompleted.vue")
                            },
                            {
                                path: '/workflow/taskHanding/timedTasksCC',
                                name: "timedTasksCC",
                                meta: {
                                    isHidden: false,
                                    icon: '',
                                    title: '抄送任务'
                                },
                                component: () => import("@/views/workflow/taskHanding/myTimedTasks/TimedTasksCC.vue")
                            }
                        ]
                    },
                    {
                        path: '/workflow/taskHanding/assignTasks',
                        name: "assignTasks",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '待分配任务'
                        },
                        component: () => import("@/views/workflow/taskHanding/assignTasks/AssignTasks.vue"),
                    },

                    // ------- 旧 ------
                    {
                        path: '/workflow/initiateApplication',
                        name: "initiateApplication",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '发起申请'
                        },
                        component: () => import("@/views/workflow/processInstance/InitiateApplication.vue"),
                    },
                    {
                        path: '/workflow/taskHanding/myProcess',
                        name: "myProcess",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '我的申请'
                        },
                        component: () => import("@/views/workflow/taskHanding/myProcess/MyProcess.vue"),
                    },
                    {
                        path: '/workflow/processDetails',
                        name: "processDetails",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '流程详情'
                        },
                        component: () => import("@/views/workflow/processInstance/ProcessDetails.vue"),
                    },
                    {
                        path: '/workflow/taskHanding/todoTasks',
                        name: "todoTasks",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '待办任务'
                        },
                        component: () => import("@/views/workflow/taskHanding/myApproval/TodoTasks.vue"),
                    },
                    {
                        path: '/workflow/taskHanding/completedTasks',
                        name: "completedTasks",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '已办任务'
                        },
                        component: () => import("@/views/workflow/taskHanding/myApproval/CompletedTasks.vue"),
                    },
                    {
                        path: '/workflow/taskHanding/myReadOnly',
                        name: "myReadOnly",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '抄送'
                        },
                        component: () => import("@/views/workflow/taskHanding/myReadOnly/MyReadOnly.vue"),
                    }
                ]
            },
            // 流程--数据填报
            {
                path: '/dataFillingProcess',
                name: "dataFillingProcess",
                meta: {
                    isHidden: false,
                    icon: '',
                    title: '数据填报'
                },
                component: () => import("@/views/dataFillingProcess/DataFillingProcess.vue"),
                redirect: {
                    name: 'claimFillingTask'
                },
                children: [
                    {
                        path: '/dataFillingProcess/claimFillingTask',
                        name: "claimFillingTask",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '认领任务'
                        },
                        component: () => import("@/views/dataFillingProcess/claimFillingTask/ClaimFillingTask.vue"),
                    },
                    {
                        path: '/dataFillingProcess/fillingTasksToDo',
                        name: "fillingTasksToDo",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '待办任务'
                        },
                        component: () => import("@/views/dataFillingProcess/fillingTasks/FillingTasksToDo.vue"),
                    },
                    {
                        path: '/dataFillingProcess/fillingTasksCompleted',
                        name: "fillingTasksCompleted",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '已办任务'
                        },
                        component: () => import("@/views/dataFillingProcess/fillingTasks/FillingTasksCompleted.vue"),
                    },
                    {
                        path: '/dataFillingProcess/fillingTasksCC',
                        name: "fillingTasksCC",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '抄送任务'
                        },
                        component: () => import("@/views/dataFillingProcess/fillingTasks/FillingTasksCC.vue"),
                    },
                ]
            },
            // 专项填报/专项实验/采样化验
            {
                path: '/specialExperiment',
                name: "specialExperiment",
                meta: {
                    isHidden: false,
                    icon: '',
                    title: '专项实验'
                },
                component: () => import("@/views/specialExperiment/SpecialExperiment.vue"),
                redirect: {
                    name: 'samplingTestingAdd'
                },
                children: [
                    {
                        path: '/specialExperiment/samplingTestingIndicators',
                        name: "samplingTestingIndicators",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '采样化验指标'
                        },
                        component: () => import("@/views/specialExperiment/samplingTesting/SamplingTestingIndicators.vue"),
                    },
                    {
                        path: '/specialExperiment/samplingTestingAdd',
                        name: "samplingTestingAdd",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '采样化验录入'
                        },
                        component: () => import("@/views/specialExperiment/samplingTesting/SamplingTestingAdd.vue"),
                    },
                    {
                        path: '/dataFillingProcess/samplingTestingView',
                        name: "samplingTestingView",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '采样化验查看'
                        },
                        component: () => import("@/views/specialExperiment/samplingTesting/SamplingTestingView.vue"),
                    },
                    {
                        path: '/dataFillingProcess/samplingTestingOnlineView',
                        name: "samplingTestingOnlineView",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '采集在线数据'
                        },
                        component: () => import("@/views/specialExperiment/samplingTesting/SamplingTestingOnlineView.vue"),
                    }
                ]
            },
            // 巡检
            {
                path: '/patrolInspection',
                name: "patrolInspection",
                meta: {
                    isHidden: false,
                    icon: '',
                    title: '巡检'
                },
                component: () => import("@/views/patrolInspection/PatrolInspection.vue"),
                redirect: {
                    name: 'patrolInspectionView'
                },
                children: [
                    {
                        path: '/specialExperiment/patrolInspectionStatus',
                        name: "patrolInspectionStatus",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '巡检状态'
                        },
                        component: () => import("@/views/patrolInspection/patrolInspectionStatus/PatrolInspectionStatus.vue")
                    },
                    {
                        path: '/patrolInspection/patrolInspectionItemType',
                        name: "patrolInspectionItemType",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '巡检项分类'
                        },
                        component: () => import("@/views/patrolInspection/patrolInspectionItemType/PatrolInspectionItemType.vue")
                    },
                    {
                        path: '/patrolInspection/patrolInspectionLocation',
                        name: "patrolInspectionLocation",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '巡检配置'
                        },
                        component: () => import("@/views/patrolInspection/patrolInspectionLocation/PatrolInspectionLocation.vue")
                    },
                    {
                        path: '/patrolInspection/patrolInspectionAdd',
                        name: "patrolInspectionAdd",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '巡检录入'
                        },
                        component: () => import("@/views/patrolInspection/patrolInspectionAdd/PatrolInspectionAdd.vue")
                    },
                    {
                        path: '/patrolInspection/patrolInspectionView',
                        name: "patrolInspectionView",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '巡检查看'
                        },
                        component: () => import("@/views/patrolInspection/patrolInspectionView/PatrolInspectionView.vue")
                    },

                ]
            },
            // 统计页面
            {
                path: '/statistics',
                name: "statistics",
                meta: {
                    isHidden: false,
                    icon: '',
                    title: '数据统计'
                },
                component: () => import("@/views/statistics/StatisticsPage.vue"),
                redirect: {
                    name: 'statisticsProcessFilling'
                },
                children: [{
                        path: '/statistics/statisticsProcessFilling',
                        name: "statisticsProcessFilling",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '填报统计(表)'
                        },
                        component: () => import("@/views/statistics/statisticsProcessFilling/StatisticsProcessFilling.vue"),
                    },
                    {
                        path: '/statistics/statisticsProcessFillingChart',
                        name: "statisticsProcessFillingChart",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '填报统计(图)'
                        },
                        component: () => import("@/views/statistics/statisticsProcessFilling/StatisticsProcessFillingChart.vue"),
                    },
                ]
            },
            // 任务管理--设计版--未使用
            {
                path: '/taskManage',
                name: "taskManage",
                meta: {
                    isHidden: false,
                    icon: '',
                    title: '任务管理（未使用）'
                },
                component: () => import("@/views/taskManage/TaskManage.vue"),
                redirect: {
                    name: 'disPatchTasksInfo'
                },
                children: [{
                        path: '/taskManage/disPatchTasksInfo',
                        name: "disPatchTasksInfo",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '派发任务'
                        },
                        component: () => import("@/views/taskManage/disPatchTasks/disPatchTasksInfo.vue"),
                    },
                    {
                        path: '/taskManage/myTaskInfo',
                        name: "myTaskInfo",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '我的任务'
                        },
                        component: () => import("@/views/taskManage/myTask/myTaskInfo.vue"),
                    },
                ]
            },
            // 运维管理-- 新版 - 关联IIoT项目
            {
                path: '/operationManage',
                name: "operationManage",
                meta: {
                    isHidden: false,
                    icon: '',
                    title: '运维管理'
                },
                component: () => import("@/views/operationManage/OperationManage.vue"),
                redirect: {
                    name: 'operationStation'
                },
                children: [{
                    path: '/operationManage/operationStation',
                    name: "operationStation",
                    meta: {
                        isHidden: false,
                        icon: '',
                        title: '运维站点'
                    },
                    component: () => import("@/views/operationManage/operationStation/OperationStation.vue"),
                    redirect: {
                        name: 'stationInfo'
                    },
                    children: [{
                            path: '/operationManage/stationInfo',
                            name: "stationInfo",
                            meta: {
                                isHidden: false,
                                icon: '',
                                title: '站点信息'
                            },
                            component: () => import("@/views/operationManage/operationStation/stationInfo/StationInfo.vue"),
                        },
                        {
                            path: '/operationManage/stationUser',
                            name: "stationUser",
                            meta: {
                                isHidden: false,
                                icon: '',
                                title: '站点人员'
                            },
                            component: () => import("@/views/operationManage/operationStation/stationUser/StationUser.vue"),
                        },
                        {
                            path: '/operationManage/IotStationConfig',
                            name: "iotStationConfig",
                            meta: {
                                isHidden: false,
                                icon: '',
                                title: 'IoT站点配置'
                            },
                            component: () => import("@/views/operationManage/operationStation/stationInfo/IotStationConfig.vue"),
                        },
                        {
                            path: '/operationManage/stationMqtt',
                            name: "stationMqtt",
                            meta: {
                                isHidden: false,
                                icon: '',
                                title: '站点主题匹配'
                            },
                            component: () => import("@/views/operationManage/operationStation/stationMqtt/StationMqtt.vue"),
                        }
                    ]
                },
                    {
                        path: '/operationManage/carbonEmissionData',
                        name: "carbonEmissionData",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '碳排放数据'
                        },
                        component: () => import("@/views/operationManage/carbonEmissionData/CarbonEmissionData.vue"),
                        redirect: {
                            name: 'carbonEmissionDailyData'
                        },
                        children: [
                            {
                                path: '/operationManage/carbonEmissionDailyData',
                                name: "carbonEmissionDailyData",
                                meta: {
                                    isHidden: false,
                                    icon: '',
                                    title: '碳排放日数据'
                                },
                                component: () => import("@/views/operationManage/carbonEmissionData/CarbonEmissionDailyData.vue"),
                            },
                            {
                                path: '/operationManage/carbonEmissionMonthlyData',
                                name: "carbonEmissionMonthlyData",
                                meta: {
                                    isHidden: false,
                                    icon: '',
                                    title: '碳排放月数据'
                                },
                                component: () => import("@/views/operationManage/carbonEmissionData/CarbonEmissionMonthlyData.vue"),
                            }
                        ]
                    }
                ]
            },
            // 设备管理
            {
                path: '/equipmentManage',
                name: "equipmentManage",
                meta: {
                    isHidden: false,
                    icon: '',
                    title: '设备管理'
                },
                component: () => import("@/views/equipmentManage/EquipmentManage.vue"),
                redirect:{name:'equipmentInfo'},
                children: [
                    {
                        path: '/equipmentManage/equipmentClassify',
                        name: "equipmentClassify",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '设备分类'
                        },
                        component: () => import("@/views/equipmentManage/equipmentClassify/EquipmentClassify.vue"),
                    },
                    {
                        path: '/equipmentManage/equipmentInfo',
                        name: "equipmentInfo",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '设备信息'
                        },
                        component: () => import("@/views/equipmentManage/equipmentInfo/EquipmentInfo.vue"),
                    },
                    {
                        path: '/equipmentManage/equipmentTopicMessage',
                        name: "equipmentTopicMessage",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '设备与主题消息关联'
                        },
                        component: () => import("@/views/equipmentManage/equipmentTopicMessage/EquipmentTopicMessage.vue"),
                    }
                ]
            },
            // 数据校准
            {
                path: '/dataCalibration',
                name: "dataCalibration",
                meta: {
                    isHidden: false,
                    icon: '',
                    title: '数据校准'
                },
                component: () => import("@/views/dataCalibration/DataCalibration.vue")
            },

            {
                path: '/laboratoryFilling',
                name: "laboratoryFilling",
                meta: {
                    isHidden: false,
                    icon: '',
                    title: '水质采样化验'
                },
                component: () => import("@/views/laboratoryFilling/LaboratoryFilling.vue")
            },
            {
                path: '/statisticalData',
                name: "statisticalData",
                meta: {
                    isHidden: false,
                    icon: '',
                    title: '数据统计'
                },
                component: () => import("@/views/statisticalData/StatisticalData.vue")
            },
            {
                path: '/dictConfig',
                name: "dictConfig",
                meta: {
                    isHidden: false,
                    icon: '',
                    title: '字典配置'
                },
                component: () => import("@/views/dictConfig/DictConfig.vue"),
                redirect: {
                    name: 'commonDict'
                },
                children: [{
                        path: '/dictConfig/commonDict',
                        name: "commonDict",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '固定字典配置'
                        },
                        component: () => import("@/views/dictConfig/commonDict/CommonDict.vue"),
                    },
                    {
                        path: '/dictConfig/businessDict',
                        name: "businessDict",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '业务字典配置'
                        },
                        component: () => import("@/views/dictConfig/businessDict/BusinessDict.vue"),
                    },
                    {
                        path: '/dictConfig/iotRelationDict',
                        name: "iotRelationDict",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: 'IOT相关配置'
                        },
                        component: () => import("@/views/dictConfig/iotRelationDict/IotRelationDict.vue"),
                    }
                ]
            },
            {
                path: '/systemSettings',
                name: "systemSettings",
                meta: {
                    isHidden: false,
                    icon: '',
                    title: '系统管理'
                },
                component: () => import("@/views/systemSettings/SystemSettings.vue"),
                redirect: {
                    name: 'themeChanging'
                },
                children: [{
                        path: '/systemSettings/menuManage',
                        name: "menuManage",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '菜单管理'
                        },
                        component: () => import("@/views/systemSettings/menuManage/MenuManage.vue"),
                    },
                    {
                        path: '/systemSettings/roleManage',
                        name: "roleManage",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '角色管理'
                        },
                        component: () => import("@/views/systemSettings/roleManage/RoleManage.vue"),
                    },
                    {
                        path: '/systemSettings/themeChanging',
                        name: "themeChanging",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '主题换肤'
                        },
                        component: () => import("@/views/systemSettings/themeChanging/ThemeChanging.vue"),
                    }
                ]
            },
            {
                path: '/versionConfig',
                name: "versionConfig",
                meta: {
                    isHidden: false,
                    icon: '',
                    title: 'APP管理'
                },
                component: () => import("@/views/versionConfig/index.vue"),
                redirect: {
                    name: 'appVersion1'
                },
                children: [{
                    path: '/versionConfig/appVersion',
                    name: "appVersion",
                    meta: {
                        isHidden: false,
                        icon: '',
                        title: '版本管理'
                    },
                    component: () => import("@/views/versionConfig/appVersion.vue"),
                },{
                    path: '/versionConfig/appVersion1',
                    name: "appVersion1",
                    meta: {
                        isHidden: false,
                        icon: '',
                        title: '版本管理1'
                    },
                    component: () => import("@/views/versionConfig/appVersion1.vue"),
                }, ]
            },
            {
                path: '/personalCenter',
                name: "personalCenter",
                meta: {
                    isHidden: false,
                    icon: '',
                    title: '个人中心'
                },
                component: () => import("@/views/personalCenter/PersonalCenter.vue"),
                redirect: {name: 'personalInformation'},
                children: [
                    {
                        path: '/personalCenter/personalInformation',
                        name: "personalInformation",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '个人信息'
                        },
                        component: () => import("@/views/personalCenter/personalInformation/PersonalInformation.vue"),
                    },
                    {
                        path: '/messageNotification',
                        name: "messageNotification",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '消息通知'
                        },
                        component: () => import("@/views/messageNotification/MessageNotification.vue"),
                    },
                ]
            },
            // 运维管理 -- 待开发
            {
                path: '/operation',
                name: "operation",
                meta: {
                    isHidden: false,
                    icon: '',
                    title: '运维管理'
                },
                component: () => import("@/views/operation/Operation.vue"),
                redirect: {
                    name: 'patrolInfo'
                },
                children: [{
                    path: '/operation/personnelList',
                    name: "personnelList",
                    meta: {
                        isHidden: false,
                        icon: '',
                        title: '人员清单'
                    },
                    component: () => import("@/views/operation/personnelList/PersonnelList.vue"),
                },
                    {
                        path: '/operation/venueList',
                        name: "venueList",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '场地清单'
                        },
                        component: () => import("@/views/operation/venueList/VenueList.vue"),
                    },
                    {
                        path: '/operation/equipmentStatus',
                        name: "equipmentStatus",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '设备状态'
                        },
                        component: () => import("@/views/operation/equipmentStatus/EquipmentStatus.vue"),
                    },
                    {
                        path: '/operation/faultReport',
                        name: "faultReport",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '人工故障报告'
                        },
                        component: () => import("@/views/operation/faultReport/FaultReport.vue"),
                    },
                    {
                        path: '/operation/laboratoryDrugMage',
                        name: "laboratoryDrugMage",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '化验药品管理'
                        },
                        component: () => import("@/views/operation/laboratoryDrugMage/LaboratoryDrugMage.vue"),
                    },





                    {
                        path: '/operation/operationConfig',
                        name: "operationConfig",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '运维配置'
                        },
                        component: () => import("@/views/operation/operationConfig/OperationConfig.vue"),
                    },
                    {
                        path: '/operation/patrolInfo',
                        name: "patrolInfo",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '巡检信息'
                        },
                        component: () => import("@/views/operation/patrol/PatrolInfo.vue"),
                    },
                    {
                        path: '/operation/earlyWarning',
                        name: "earlyWarning",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '预警'
                        },
                        component: () => import("@/views/operation/earlyWarning/EarlyWarning.vue"),
                    },
                    {
                        path: '/operation/alarmInfo',
                        name: "alarmInfo",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '报警'
                        },
                        component: () => import("@/views/operation/alarmInfo/AlarmInfo.vue"),
                    },
                    {
                        path: '/operation/repair',
                        name: "repair",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '报修'
                        },
                        component: () => import("@/views/operation/repair/RepairInfo.vue"),
                    },
                    {
                        path: '/operation/medRecord',
                        name: "medRecord",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '用药记录'
                        },
                        component: () => import("@/views/operation/medRecord/MedRecord.vue"),
                    },
                    {
                        path: '/operation/drugInventory',
                        name: "drugInventory",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '药品库存'
                        },
                        component: () => import("@/views/operation/drugInventory/DrugInventory.vue"),
                    },
                    {
                        path: '/operation/siteLeader',
                        name: "siteLeader",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '站点负责人'
                        },
                        component: () => import("@/views/operation/siteLeader/SiteLeader.vue"),
                    },

                    {
                        path: '/operation/communicationStatus',
                        name: "communicationStatus",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '系统通信状态'
                        },
                        component: () => import("@/views/operation/communicationStatus/CommunicationStatus.vue"),
                    },
                    {
                        path: '/operation/spareParts',
                        name: "spareParts",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '备品备件'
                        },
                        component: () => import("@/views/operation/spareParts/SpareParts.vue"),
                    },
                    {
                        path: '/operation/vehicleRecord',
                        name: "vehicleRecord",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '车辆使用记录'
                        },
                        component: () => import("@/views/operation/vehicleRecord/VehicleRecord.vue"),
                    },
                    {
                        path: '/operation/checkInOut',
                        name: "checkInOut",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '班组签到'
                        },
                        component: () => import("@/views/operation/checkInOut/CheckInOut.vue"),
                    },
                    {
                        path: '/operation/dutyPersonnel',
                        name: "dutyPersonnel",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '值班人员'
                        },
                        component: () => import("@/views/operation/dutyPersonnel/DutyPersonnel.vue"),
                    }
                ]
            },
            // 测试代码部分
            {
                path: '/test',
                name: "test",
                meta: {
                    isHidden: false,
                    icon: '',
                    title: '测试代码'
                },
                component: () => import("@/views/test/Test.vue"),
                redirect: {
                    name: 'treeGrid'
                },
                children: [{
                        path: '/test/treegrid',
                        name: "treeGrid",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: '树结构'
                        },
                        component: () => import("@/views/test/TreeGrid1.vue"),
                    },
                    {
                        path: '/test/grpc',
                        name: "grpc",
                        meta: {
                            isHidden: false,
                            icon: '',
                            title: 'grpc'
                        },
                        component: () => import("@/views/test/grpc.vue"),
                    }
                ]
            },
            {
                path: "/warehouse",
                name: "warehouse",
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () =>
                  import(
                    /* webpackChunkName: "about" */ "../views/warehouse/warehouseList.vue"
                  ),
              },
              {
                path: "/warehouseDetail",
                name: "warehouseDetail",
                component: () =>
                  import(
                    /* webpackChunkName: "about" */ "../views/warehouse/warehouseDetail.vue"
                  ),
              },


        ]
    },

];

// 创建路由实例
const router = createRouter({
    history: routerHistory,
    routes,
});

// 全局守卫：登录拦截 本地没有存token,请重新登录
// router.beforeEach((to, from, next) => {
//     // 判断有没有登录
//     if (!localStorage.getItem('token')) {
//         if (to.name == "login") {
//             next();
//         } else {
//             router.push('login')
//         }
//     } else {
//         next();
//     }
// });

// router.beforeEach(async (to, from, next) => {
//     let app = router.app.$data || {isAuthenticated: false} ;
//     if (app.isAuthenticated) {
//         //already signed in, we can navigate anywhere
//         next()
//     } else if (to.matched.some(record => record.meta.requiresAuth)) {
//         //authentication is required. Trigger the sign in process, including the return URI
//         router.app.authenticate(to.path).then(() => {
//             console.log('authenticating a protected url:' + to.path);
//             next();
//         });
//     } else {
//         //No auth required. We can navigate
//         next()
//     }
// });


export default router
